<template>
  <section id="about-me">
    <div class="py-12"></div>

    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3" data-aos="fade-down">
        Hakkımda
      </h2>

      <v-responsive class="mx-auto mb-8" width="56">
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>

      <v-responsive
        class="mx-auto title font-weight-light mb-8"
        max-width="720"
        data-aos="fade-right"
      >
        <p>
          Merhaba! 👋🏻 <br>
          {{this.workExp}} yıldır <b>"Bilgi Teknolojileri"</b> alanında çeşitli departmanlarda görev yaptım. 
          Frontend Developer rolünde, aktif projelerde yer almaktayım.<br>
          Udemy üzerinde tüm bilgi birikimimi profesyonel olarak paylaşmayı tercih ettim. <br>
          İlk eğitim setim <a href="https://www.udemy.com/course/wordpress-ile-adim-adim-internet-sitesi-kurma-rehberi/?referralCode=54D5931F5938F51586D4" target="_blank">"Wordpress İle Adım Adım İnternet Sitesi Kurma Rehberi"</a> 
          <br>Daha detaylı bilgi almak için iletişim kanallarından bana ulaşabilirsin.
        </p>
      </v-responsive>

      <v-avatar class="elevation-12 mb-8" size="200" data-aos="zoom-in">
        <v-img
          src="../assets/cemali-vcard.png"
          alt="portrait"
          position="top"
        ></v-img>
      </v-avatar>

      <v-row justify="space-around" class="mb-4" data-aos="fade-up">
        <div class="group mb-8">
          <a
            v-for="icon in icons"
            :key="icon.icon"
            :href="icon.link"
            target="_blank"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon x-large class="mx-3 hvr-grow" v-on="on">
                  {{ icon.icon }}</v-icon
                >
              </template>
              <span>{{ icon.tooltip }}</span>
            </v-tooltip>
          </a>
        </div>
      </v-row>

      <v-btn
        class="white--text font-weight-black hvr-grow"
        color="#01d28e"
        rounded
        large
        data-aos="fade-up"
        @click="$vuetify.goTo('#contact')"
      >
           İLETİŞİM  </v-btn>
    </v-container>

    <div class="py-12"></div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      age: 0,
      icons: [
        {
          icon: 'mdi-github',
          link: 'https://github.com/cemalivive',
          tooltip: 'GitHub'
        },
        {
          icon: 'mdi-linkedin',
          link: 'https://www.linkedin.com/in/cemaligencer',
          tooltip: 'LinkedIn'
        }
      ]
    }
  },
  created() {
    const startWork = new Date('2008, 06, 01')
    var ageDifMs = Date.now() - startWork.getTime()
    var workDate = new Date(ageDifMs)
    this.workExp = Math.abs(workDate.getUTCFullYear() - 1970)
  }
}
</script>

<style scoped>
/* Grow */
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
