<template>
  <section id="vita">
    <div class="py-12"></div>

    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3" data-aos="zoom-in">
        DENEYİMLER
      </h2>

      <v-responsive class="mx-auto mb-8" width="56">
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>

      <v-responsive>
        <v-timeline :align-top="false">
          <v-timeline-item
            v-for="(item, i) in items"
            :key="i"
            color="#01d28e"
            :icon="item.icon ? item.icon : 'mdi-plus'"
            :data-aos="i % 2 == 0 ? 'fade-left' : 'fade-right'"
          >
            <span slot="opposite">{{ item.from }} - {{ item.to }}</span>
            <v-card class="elevation-2">
              <v-card-title class="headline">{{ item.title }}</v-card-title>
              <v-card-text class="text-justify">
                {{ item.text }}
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-responsive>
    </v-container>
    <div class="py-12"></div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          icon: 'mdi-code-braces',
          from: '2020',
          to: 'Halen',
          title: 'Turkish Airlines',
          text: 'Danışmanlık, Frontend Developer'        
          }, 
         {
          icon: 'mdi-code-braces',
          from: '2020',
          to: '2020',
          title: 'AI Base Technology',
          text: 'Danışmanlık, Frontend Developer'        
          }, 
         {
          icon: 'mdi-code-braces',
          from: '2020',
          to: '2020',
          title: 'Muhiku.com',
          text: 'Danışmanlık'        
          }, 
         {
          icon: 'mdi-code-braces',
          from: '2018',
          to: '2020',
          title: 'Altıncı Cadde',
          text: 'Frontend Developer, Software Developer'        
          },         
         {
          icon: 'mdi-code-braces',
          from: '2014',
          to: '2018',
          title: 'Vivense',
          text: 'Frontend Developer'        
          },        

         {
          icon: 'mdi-code-braces',
          from: '2010',
          to: '2014',
          title: 'Artist Web & Grafik',
          text: 'Founder'        
          },
 
        {
          icon: 'mdi-code-braces',
          from: '2008',
          to: '2019',
          title: 'CETA Bilişim',
          text: 'Founder'        
          }
      ]
    }
  }
}
</script>

<style></style>
