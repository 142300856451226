<template>
  <v-footer color="#313131" padless height="200" class="align-end">
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="icon in icons"
        :key="icon.icon"
        class="mx-4 white--text"
        icon
        x-large
        :href="icon.link"
        target="_blank"
      >
        <v-icon size="24px">{{ icon.icon }}</v-icon>
      </v-btn>
      <v-col
        class="py-2 text-center white--text"
        justify="space-start"
        cols="12"
      >
        <div
          class="font-weight-light grey--text text--lighten-1 text-center"
          @click.stop="dialog = true"
        >
          @İstanbul
        </div>

        <div class="font-weight-light grey--text text--lighten-1 text-center">
          &copy; {{ new Date().getFullYear() }} by Cemali — Made with
      
          <v-icon small class=" grey--text">mdi-vuetify</v-icon>
        </div>

        
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    icons: [
      {
        icon: 'fab fa-github',
        link: 'https://github.com/cemalivive',
        tooltip: 'GitHub'
      },
      {
        icon: 'fab fa-linkedin',
        link: 'https://www.linkedin.com/in/cemaligencer',
        tooltip: 'LinkedIn'
      }
    ]
  })
}
</script>

<style></style>
